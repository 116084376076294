import { Dispatch, SetStateAction } from "react";
import {
  ChangesDetail,
  HQSiteChangesDTO,
  HQSiteChangesDetailDTO,
  HQSiteDetailsDTO,
} from "../../../api-client-nswag/taxportal-client";
import { upgradeSites } from "../../../pages/UpgradeSites/UpgradeSites.types";
import dxDataGrid from "devextreme/ui/data_grid";

export enum UpgradeSitesVars {
  MIN_SELECTED = 1,
}

export interface changesDetailData {
  pending: boolean;
  changes: HQSiteChangesDetailDTO[];
}

export interface manageSitesSelected {
  pending: boolean;
  sites: HQSiteDetailsDTO[];
}

export interface changesSelected {
  pending: boolean;
  changes: detailsGridData[];
}

export interface SitesSelectedProps {
  selectedSitesData: HQSiteDetailsDTO[];
  selectedSites: number[];
  setSelectedSites: Dispatch<SetStateAction<number[]>>;
  templateSelected: string;
  toggleSitesGrids: (hideSelected: boolean) => void;
}

export interface ChangesDetailsProps {
  parentKey: number;
  parentComponent: any;
  selectedSites: number[];
  selectedChanges: HQSiteChangesDTO[];
  templateSelected: string;
  changesWStatus: HQSiteChangesDTO[];
  isPending: boolean;
  modifySelectedChanges: (newData: HQSiteChangesDTO, isToDownload?: boolean) => void;
  removingSelectedChanges: (rowKey: number) => void;
}

export interface ChangesCardProps {
  selectedChangesDetails: SelectedChangeData;
  isReviewOnly: boolean;
  selectedDetails?: SelectedDetailsData[];
  setSelectedDetails?: Dispatch<SetStateAction<SelectedDetailsData[]>>;
  parentComponent?: dxDataGrid<any, any>;
}

export interface SitesGridProps {
  sitesData: upgradeSites;
  selectedSites: number[];
  setSelectedSites: Dispatch<SetStateAction<number[]>>;
  setSelectedSitesData: Dispatch<SetStateAction<HQSiteDetailsDTO[]>>;
  dropDown: string;
  setDropDown: Dispatch<SetStateAction<string>>;
  fetchData: () => void;
  toggleSitesGrids: (hideSelected: boolean) => void;
  setPageNumber: Dispatch<SetStateAction<number>>;
  setSearchTerm: Dispatch<SetStateAction<string | null>>;
}

export interface ModalSitesChangesProps {
  handleClose: () => void;
  isDisplayModal: boolean;
  idUpgradeSiteTracks: number | undefined;
}

export interface ModalReviewChangesProps {
  selectedChangesDetails: SelectedChangeData;
  isDisplayModal: boolean;
  handleClose: () => void;
  isReviewOnly: boolean;
  selectedDetails?: SelectedDetailsData[];
  setSelectedDetails?: Dispatch<SetStateAction<SelectedDetailsData[]>>;
  parentComponent?: dxDataGrid<any, any>;
}

export interface SelectedChangeData extends HQSiteChangesDetailDTO {
  detailSelected?: ChangesDetail;
}

export interface SelectedDetailsData {
  comparisonId: string;
  changeId: string;
  details: string[];
}

export interface selectChangesProps {
  selectedChangesDetails: SelectedChangeData;
  selectedDetails?: SelectedDetailsData[];
  setSelectedDetails: Dispatch<SetStateAction<SelectedDetailsData[]>>;
  parentComponent?: dxDataGrid<any, any>;
}

export interface detailsGridData {
  name: string;
}

export interface manageChanges {
  pending: boolean;
  changes: HQSiteChangesDTO;
}

export interface selectedOptionType {
  value: number;
  label: string | undefined;
}

export const constants = {
  ACTIVE: "ACTIVE",
  BUTTON_REVIEW_CHANGES: "Initiate Upgrade",
  BUTTON_CONFIRM: "Proceed to Upgrade",
  BUTTON_CANCEL: "Cancel",
  BUTTON_BACK: "Back to Upgrade Sites",
  BUTTON_DOWNLOAD_EXCEL: "Download Excel",
  MODAL_TITLE: "Change Details",
  SITES_SELECTED_TITLE: "Review changes",
  DETAILS_TITLE: "Change Analysis",
  STATUS_COLUMN: "status",
  UPGRADED_BY_COLUMN: "upgradeBy",
  DATE_COLUMN: "upgradeDate",
  DISABLED_CHECK: "#ddd",
  ALERT_MESSAGE: "Changes highlighted in red will impact the code. Please proceed carefully.",
  STATUSES: {
    SUCCESS: "Success",
    ERROR: "Error",
    PARTIALLY_SUCCESS: "Partially Success",
    PENDING: "Pending...",
    EMPTY: "Empty response",
    SPECIAL_STATUS: "Failed to fetch",
  },
  COLUMNS: {
    HQ_SITE_ID: "SiteID",
    ORG_ID: "Organisation ID",
    MDM_MASTER_CLIENT_ID: "Master ClientID",
    MDM_CLIENT_ID: "ClientID",
    NAME: "Site Name",
    TEMPLATE_NAME_OLD: "Original Site Template",
    TEMPLATE_NAME_NEW: "Latest Site Template Applied",
    UPGRADE_DATE: "Upgrade Date",
    STATUS: "Upgrade Status",
    UPGRADED_BY: "Upgraded By",
  },
  FILE_SITE_UPGRADE_NAME: "SiteUpgarde_Result",
  FILE_COMPARISON_NAME: "Comparison_Result",
  EXCEL_FILE_EXTENSION: "xlsx",
  ZIP_FILE_EXTENSION: "zip",
  ALERT_TEXT_CLASS: "alert-text",
  ADDITIONAL_OFFSET: 300,
  BUFFER: 100,
};
