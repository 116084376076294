import { useState, useEffect, useContext, useRef } from "react";
import {
  DataGrid,
  Column,
  FilterRow,
  HeaderFilter,
  LoadPanel,
  Scrolling,
  SearchPanel,
  Selection,
  Toolbar,
  Item,
  Paging,
} from "devextreme-react/data-grid";
import { OidcIdentityContext } from "@rsmus/react-auth";

import { Pagination } from "@mui/material";
import useSnackbar from "../../hooks/useSnackbar";

import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import { dataGridConfig, defaultPageSize } from "../../constants";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import { convertUTCTimeToLocal } from "../../util/Utils";
import { calculateGridHeight } from "../../util/Utils";
import { useRecoilValue } from "recoil";
import { updateSiteMetrics } from "./SiteMetrics.types";
import AppNameToggleForSiteMetric, { getAppNameSiteMetric } from "../../cui/commonComponents/AppNameToggleForSiteMetric";
import SitesRequestsToggle, { getSitesRequests } from "../../cui/commonComponents/SitesRequestsToggle";
import { isEmpty, isNull } from "lodash";
import { GetAllSiteMetricsDTO } from "../../api-client-nswag/taxportal-client";
import { SitesBreakdownPBI } from "./SitesBreakDownPBI";
import React from "react";

type DatagridRefType = DataGrid;

export const SiteMetrics = () => {
  const { user, logout } = useContext(OidcIdentityContext);
  const [data, setData] = useState<updateSiteMetrics>({ pending: true, fulfilled: true, sites: [], totalCount: 0 });
  const [selectedRows, setSelectedRows] = useState([]);
  const { showSnackbar, showSuccessSnackbar } = useSnackbar();
  const dataGridRef = useRef<DatagridRefType>(null);
  const [pageNumber, setpageNumber] = useState(1);
  const appName = useRecoilValue(getAppNameSiteMetric);
  const siteRequests = useRecoilValue(getSitesRequests);
  const [searchTerm, setSearchTerm] = useState<string | null>(null)
  const [exportData, setExportData] = useState<GetAllSiteMetricsDTO[] |undefined>();

  const fetchSites = async () => {
    try {
      setData({ pending: true, fulfilled: true, sites: [], totalCount: 0 });
      const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
      const data = await taxPortalClientApi.highQ_GetSiteMetricsInfo(appName, siteRequests, startIndex, defaultPageSize)
      setData({ pending: false, fulfilled: true, sites: data.data?.slice(startIndex, startIndex+defaultPageSize), totalCount: data.totalCount });
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  }

  const fetchExportSites = async() => {
    const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
    const data = await taxPortalClientApi.highQ_GetSiteMetricsInfo(appName, siteRequests, startIndex, defaultPageSize)
    setExportData(data.data);
  }

  const fetchSitesBySearchTerms = async () => {
    try {
        setData({ pending: true, fulfilled: true, sites: [], totalCount:0})
        const res = await taxPortalClientApi.highQ_GetSiteMetricsInfoSearch(appName, siteRequests, searchTerm);
        if(res != null){
          setData({ pending: false, fulfilled: true, sites: res.data, totalCount: res.totalCount });
        }
        else{
          setData({ pending: false, fulfilled: true, sites: [], totalCount: 0 });
      }
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };

  const cellRenderForDate = (e: any) => {
    return <div>{convertUTCTimeToLocal(e.data.created)}</div>;
  };

  const cellRender7216 = (e: any) => {
    if (e.data.is7216ConsentReceived === true)
      return "Yes"
    else
      return "No"
  }
  const cellRenderMDM = (e: any) => {
    if (e.data.mdmRegistered === true)
      return "Yes"
    else
      return "No"
  }
  const onSelectionChanged = (e: any) => {
    setSelectedRows(e.selectedRowsData);
  };

  // const refreshButtonOptions = {
  //   icon: "refresh",
  //   onClick: async () => {
  //     setSearchTerm(null);
  //     if (dataGridRef.current) {
  //       dataGridRef.current.instance.clearFilter();
  //     }
  //     fetchSites();
  //   },
  // };

  // const exportButtonOptions = {
  //   icon: "exportxlsx",
  //   onClick: async () => {
  //     try {
  //       var response = await taxPortalClientApi.highQ_ExportToExcelForSiteMetrics(appName, exportData).then((res: any) => {
  //         const url = URL.createObjectURL(new Blob([res.data]));
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", appName + "-" + "Site Creation Summary.xlsx");
  //         link.click();
  //       });
  //       showSuccessSnackbar({ message: SnackbarMessage.EXPORT_TO_EXCEL_SUCCESS })
  //     }
  //     catch (error) {
  //       showSnackbar({ message: SnackbarMessage.EXPORT_TO_EXCEL_FAIL, severity: "error" });
  //     }
  //   }
  // }

  useEffect(() => {
      fetchExportSites();
    // eslint-disable-next-line
  }, [appName]);

  // useEffect(() => {
  //   if(!isNull(searchTerm) && !isEmpty(searchTerm)){
  //     fetchSitesBySearchTerms();
  //   }
  //   // eslint-disable-next-line
  // }, [appName,searchTerm]);

  return (
    <div style={{ margin: "20px 20px 20px 20px", display: "flex", flexDirection: "column" }}>
    <div style={{
          display: "flex"
        }}>
      <h2>Site Metrics</h2>
      <AppNameToggleForSiteMetric />
    </div>
    <div>
      {appName === "Sites" ? (
        <div style={{ position: "relative", marginTop: "20px" }}>
          <SitesBreakdownPBI appName={appName} exportData = {exportData} />
        </div>
      ) : (
        // <>
        //   <DataGrid
        //     height={calculateGridHeight(270)}
        //     onSelectionChanged={onSelectionChanged}
        //     {...dataGridConfig}
        //     dataSource={data.sites}
        //     noDataText={data.pending ? "Loading..." : "No data"}
        //     allowColumnResizing={true}
        //     columnAutoWidth={false}
        //     ref={dataGridRef}
        //   >
        //     <Selection mode="multiple" selectAllMode={"allPages"} showCheckBoxesMode={"onClick"} />
        //     <Scrolling mode="virtual" columnRenderingMode="virtual" />
        //     <LoadPanel enabled={false} />
        //     <FilterRow visible={true} />
        //     <Paging enabled={false} />
        //     <HeaderFilter visible={true} />
        //     <SearchPanel
        //       placeholder={"Global Search..."}
        //       onTextChange={(e) => setSearchTerm(e)}
        //       visible
        //     />
        //     <Toolbar>
        //       <Item location="before">{/* <SitesRequestsToggle /> */}</Item>
        //       <Item widget="dxButton" options={exportButtonOptions} name="alignRight" />
        //       <Item name="searchPanel" location="after" />
        //       <Item widget="dxButton" options={refreshButtonOptions} name="alignRight" />
        //     </Toolbar>
  
        //     <Column width="70px" alignment="left" dataField="hqSiteId" caption={"SiteID"}></Column>
        //     <Column width="70px" alignment="left" dataField="orgId" caption={"Organisation Id"}></Column>
        //     <Column width="90px" alignment="left" dataField="mdmClientId" caption={"Master ClientId"}></Column>
        //     <Column width="90px" alignment="left" dataField="capability" caption={"Capability"}></Column>
        //     <Column width="230px" dataField="hqClientName" caption={"Site Name"}></Column>
        //     <Column width="190px" dataField="templateName" caption={"Site Template"}></Column>
        //     <Column width="120px" alignment="left" cellRender={cellRenderMDM} dataField="mDMRegistered" caption={"MDM Registered"}></Column>
        //     <Column width="100px" dataField="country" caption={"Country"}></Column>
        //     <Column width="70px" alignment="left" dataField="year" caption={"Year"}></Column>
        //     <Column width="30px" alignment="left" cellRender={cellRender7216} dataField="is7216ConsentReceived" caption={"Is7216"}></Column>
        //     <Column width="170px" dataField="createdBy" caption={"Site Created by"}></Column>
        //     <Column
        //       width="190px"
        //       cellRender={cellRenderForDate}
        //       dataField="Site Created Date / Time"
        //       caption={"Site Created Date / Time"}
        //     />
        //   </DataGrid>
  
        //   <div
        //     style={{
        //       display: "flex",
        //       justifyContent: "flex-end",
        //       margin: "5px 0 0 0",
        //     }}
        //   >
        //     <Pagination
        //       onChange={(e: any, pageNumber: any) => setpageNumber(pageNumber)}
        //       count={Math.ceil(data.totalCount / defaultPageSize)}
        //       variant="outlined"
        //       shape="rounded"
        //     />
        //   </div>
        // </>
        null
      )}
    </div>
  </div> 
);
};
