import Dropdown from "react-multilevel-dropdown";
import { GetAllRolesDTO } from "../../api-client-nswag/taxportal-client";
import { useState } from "react";
import "./NestedDropDown.css";
interface NestedDropDownTypes {
  rolesData: GetAllRolesDTO[];
  provideAccess: boolean;
  handleRoleClick: any;
  handleClick: any;
  handleClickApp: any;
  handleExternalUser: any;
  handleThirdPartyUser: any;
  appName: string;
  handleTESSForIsClick:()=> void;
  handleIURClick:any;
}
const NestedDropDown = ({
  rolesData,
  provideAccess,
  handleRoleClick,
  handleClick,
  handleClickApp,
  handleExternalUser,
  handleThirdPartyUser,
  appName,
  handleTESSForIsClick,
  handleIURClick
}: NestedDropDownTypes) => {
  const options = [
    "IDM and CEM",
    "Assign User to site",
    "Check Status",
    "Remove User",
  ];
  return (
    <div>
      {appName === "IS" ? (
        <Dropdown isDisabled={provideAccess} title="RUN" buttonVariant="tertiary">
          <Dropdown.Item onClick={handleTESSForIsClick}>TESS</Dropdown.Item>
        </Dropdown>
      ) : (
        <Dropdown title="RUN" buttonVariant="tertiary">
          <Dropdown.Item>
            <div style={{ color: provideAccess ? "lightgray" : "black" }}>
              Provide Access
            </div>
            {!provideAccess && (
              <Dropdown.Submenu>
                {rolesData != null &&
                  rolesData.length > 0 &&
                  rolesData.map((e: any) => {
                    return (
                      <Dropdown.Item
                        onClick={() => {
                          handleRoleClick(e.roleId, e.name);
                        }}
                        key={e.roleId}
                      >
                        {e.name}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Submenu>
            )}
          </Dropdown.Item>
          <Dropdown.Item onClick={handleClick}>
            <div style={{ color: provideAccess ? "lightgray" : "black" }}>
              Content Admin
            </div>
          </Dropdown.Item>
          <Dropdown.Item onClick={handleIURClick}>
            <div style={{ color: provideAccess ? "lightgray" : "black" }}>
              Internal User Isheet
            </div>
          </Dropdown.Item>
          <Dropdown.Item>
            <div style={{ color: "black" }}>Application</div>
            <Dropdown.Submenu>
              <Dropdown.Item onClick={() => handleClickApp("TESS")}>
                TESS
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleClickApp("L1Support")}>
                {" "}
                L1Support
              </Dropdown.Item>
            </Dropdown.Submenu>
          </Dropdown.Item>
          <Dropdown.Item>
            <div style={{ color: provideAccess ? "lightgray" : "black" }}>
              External User
            </div>
            {!provideAccess && (
              <Dropdown.Submenu>
                {options.map((e) => {
                  return (
                    <Dropdown.Item onClick={() => handleExternalUser(e)}>
                      {e}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Submenu>
            )}
          </Dropdown.Item>
          <Dropdown.Item>
            <div style={{ color: provideAccess ? "lightgray" : "black" }}>
              Third-Party User
            </div>

            {!provideAccess && (
              <Dropdown.Submenu>
                {options.map((e) => {
                  return (
                    <Dropdown.Item onClick={() => handleThirdPartyUser(e)}>
                      {e}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Submenu>
            )}
          </Dropdown.Item>
        </Dropdown>
      )}
    </div>
  );
};

export default NestedDropDown;
