import { useState, useEffect, useContext, useRef } from "react";
import {
    DataGrid,
    Column,
    FilterRow,
    HeaderFilter,
    LoadPanel,
    Scrolling,
    SearchPanel,
    Paging,
    Selection,
    Toolbar,
    Item,
} from "devextreme-react/data-grid";
import { OidcIdentityContext } from "@rsmus/react-auth";

import { Button, FormControl, IconButton, MenuItem, Pagination, Select, SelectChangeEvent } from "@mui/material";
import useSnackbar from "../../hooks/useSnackbar";

import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import {
    GetAllSitesInfoDTO,
    SelectName,
    ServiceBusEnum,
    ServiceMessage,
    SyncTypeEnum,
} from "../../api-client-nswag/taxportal-client";
import { dataGridConfig, defaultPageSize } from "../../constants";
import { updateOrgs } from "../UpdateScreen/UpdateSiteInfo.types";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import { convertUTCTimeToLocal } from "../../util/Utils";
import { calculateGridHeight } from "../../util/Utils";
import { useRecoilValue } from "recoil";
import { isEmpty, isNull } from "lodash";
import { DialogBox } from "../CreateSites/DialogBox";
import CustomizeOrgName from "./CustomizeOrgName";
import UpdateOrgName from "./UpdateOrgName";

type DatagridRefType = DataGrid;
export interface selectedRowDataTypesOrg {
    orgName: string;
    orgId: number;
  }

export const ThirdpartyOrganization = () => {
    const { user, logout } = useContext(OidcIdentityContext);
    const [data, setData] = useState<updateOrgs>({ pending: true, fulfilled: true, sites: [], totalCount: 0 });
    const [selectedRows, setSelectedRows] = useState<selectedRowDataTypesOrg[]>([]);
    const { showSnackbar } = useSnackbar();
    const { showSuccessSnackbar } = useSnackbar();
    const dataGridRef = useRef<DatagridRefType>(null);
    const [pageNumber, setpageNumber] = useState(1);
    const [searchTerm, setSearchTerm] = useState<string | null | undefined>(null)
    const [isDisplayOrgName, setIsDisplayOrgName] = useState(false)
    const [displayUpdateOrgName, setDisplayUpdateOrgName] = useState(false)
    const [orgName, setOrgName] = useState<string>("")
    const [flag, setFlag] = useState(false);
    const [message, setMessage] = useState("")
    const [orgText, setOrgText] = useState("")


    const fetchSites = async () => {
        try {
            setData({ pending: true, fulfilled: true, sites: [], totalCount: 0 });
            const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
            const data = await taxPortalClientApi.highQ_HQOrganizationFromDb(startIndex, defaultPageSize);
            setData({ pending: false, fulfilled: true, sites: data.data, totalCount: data.totalCount });
        } catch (error) {
            showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
        }
    };

    const handleAddClick = () => {
        setIsDisplayOrgName(true);
    };
    const handleUpdateClick = () => {
        setDisplayUpdateOrgName(true);
        setOrgText(selectedRows[0].orgName)
    };
    const cellRender = (e: any) => {
        return <div>{convertUTCTimeToLocal(e.data.lastModified)}</div>;
    };
    const cellRenderForDate = (e: any) => {
        return <div>{convertUTCTimeToLocal(e.data.created)}</div>;
    };

    const cellRenderForMessage = (e: any) => {
        if (e.data.message === null && e.data.status === null) {
            return null;
        }
        return <div title={e.data.message}>{e.data.status}</div>;
    };
    const onSelectionChanged = (e: any) => {
        setSelectedRows(e.selectedRowsData);
    };

    const refreshButtonOptions = {
        icon: "refresh",
        onClick: async () => {
            setSearchTerm(null);
            if (dataGridRef.current) {
                dataGridRef.current.instance.clearFilter();
            }
            fetchSites();
        },
    };

    useEffect(() => {
        if (searchTerm === null || searchTerm?.length === 0) {
            fetchSites();
        }
        // eslint-disable-next-line
    }, [pageNumber, searchTerm]);
    const handleContinueOrgName = async () => {
        try {
            showSnackbar({ message: SnackbarMessage.CREATE_THIRDPARTY_ORGS });
            var response = await taxPortalClientApi.highQ_CreateThirdPartyOrganization(orgName, user?.profile.preferred_username as string,);
            const isExists = !isNull(response) && !isEmpty(response);
            if (isExists){
                setMessage(response);
                setFlag(true);
            }
            else {
                setIsDisplayOrgName(false)
                setFlag(false);
                showSuccessSnackbar({
                    message: SnackbarMessage.CREATE_THIRDPARTY_ORGS_SUCCESS,
                });
            }
        }
        catch (error) {
            showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
        }
    }
    const handleContinueUpdateOrgName = async () => {
        try {
            showSnackbar({ message: SnackbarMessage.UPDATE_THIRDPARTY_ORGS});
            var response = await taxPortalClientApi.highQ_UpdateThirdPartyOrganization(orgName, selectedRows[0].orgId.toString() ,user?.profile.preferred_username as string);
            const isExists = !isNull(response) && !isEmpty(response);
            if (isExists){
                setMessage(response);
                setFlag(true);
            }
            else {
                setDisplayUpdateOrgName(false)
                setFlag(false);
                setSelectedRows([])
                showSuccessSnackbar({
                    message: SnackbarMessage.UPDATE_THIRDPARTY_ORGS_SUCCESS,
                });
            }
        }
        catch (error) {
            showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
        }
    }
    const handleCloseOrgName = () => {
        setIsDisplayOrgName(false);
        setFlag(false);
    }

    const handleCloseUpdateOrgName = () => {
        setDisplayUpdateOrgName(false);
        setSelectedRows([])
        setFlag(false);
    }

    return (
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <div>
                <div style={{
                    display: "flex",
                    margin: "10px 0 10px 0",
                }}>
                    <h2>Third Party Organizations</h2>

                </div>

            </div>
            <DialogBox
                isDisplayModal={isDisplayOrgName}
                handleClose={handleCloseOrgName}
                handleContinue={handleContinueOrgName}
                btnOpenText={"Save"}
                title={"Customize Organization name"}
            >
                <CustomizeOrgName
                    message={message}
                    flag={flag}
                    setOrgName={setOrgName}
                />
            </DialogBox>
            <DialogBox
                isDisplayModal={displayUpdateOrgName}
                handleClose={handleCloseUpdateOrgName}
                handleContinue={handleContinueUpdateOrgName}
                btnOpenText={"Save"}
                title={"Customize Organization name"}
            >
                <UpdateOrgName
                    message={message}
                    flag={flag}
                    setOrgName={setOrgName}
                    orgText={orgText}
                />
            </DialogBox>

            <DataGrid
                height={calculateGridHeight(270)}
                onSelectionChanged={onSelectionChanged}
                selectedRowKeys={selectedRows}
                {...dataGridConfig}
                dataSource={data.sites}
                noDataText={data.pending ? "Loading..." : "No data"}
                allowColumnResizing={true}
                ref={dataGridRef}
            >
                <Selection mode="multiple" selectAllMode={"allPages"} showCheckBoxesMode={"onClick"} />
                <Scrolling mode="standard" />
                <LoadPanel enabled={false} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <SearchPanel placeholder={"Global Search..."} onTextChange={(e) => {
                    setSearchTerm(e);
                }} visible />
                <Toolbar>
                    <Item location="after">
                        <Button
                            onClick={handleAddClick}
                            variant="outlined"
                        >
                            Add Organization
                        </Button>
                    </Item>
                    <Item location="after">
                        <Button
                            onClick={handleUpdateClick}
                            disabled={selectedRows.length == 1 ? false : true}
                            variant="outlined"
                        >
                            Update Organization
                        </Button>
                    </Item>
                    <Item name="searchPanel" location="after" />
                    <Item widget="dxButton" options={refreshButtonOptions} name="alignRight" />
                </Toolbar>
                <Scrolling columnRenderingMode="virtual" />
                <Column width="80px" alignment="left" dataField="orgId" caption={"Org ID"}></Column>
                <Column dataField="orgName" caption={"Organization Name"}></Column>
                <Column width="170px" dataField="createdBy" caption={"Org Created by"}></Column>
                <Column
                    width="190px"
                    cellRender={cellRenderForDate}
                    dataField="created"
                    caption={"Org Created Date / Time"}
                />
                <Column cellRender={cellRenderForMessage} width="70px" dataField="status" caption={"Status"} />
                <Column width="170px" dataField="lastModifiedBy" caption={"Last Updated by"} />
                <Column
                    width="190px"
                    cellRender={cellRender}
                    dataField="lastModified"
                    caption={"Last Update Date/Time"}
                />
            </DataGrid>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "5px 0 0 0",
                }}
            >
                <Pagination
                    onChange={(e: any, pageNumber: any) => {
                        setpageNumber(pageNumber);
                    }}
                    count={Math.ceil(data.totalCount / defaultPageSize)}
                    variant="outlined"
                    shape="rounded"
                />
            </div>
        </div>
    );
};
