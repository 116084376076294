import { useContext, useEffect, useRef, useState } from "react";
import {
  DataGrid,
  Column,
  FilterRow,
  HeaderFilter,
  LoadPanel,
  Scrolling,
  SearchPanel,
  Paging,
  Selection,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { useRecoilValue } from "recoil";

import { Button, FormControl, MenuItem, Pagination, Select, SelectChangeEvent } from "@mui/material";

import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import {
  GetAllSitesDTO,
  SelectName,
  ServiceBusEnum,
  ServiceMessage,
  SyncTypeEnum,
} from "../../api-client-nswag/taxportal-client";
import { updateSites } from "../UpdateScreen/UpdateSiteInfo.types";
import { dataGridConfig, defaultPageSize, refreshFlagAtom } from "../../constants";
import { calculateGridHeight, convertUTCTimeToLocal } from "../../util/Utils";
import useSnackbar from "../../hooks/useSnackbar";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import AppNameToggle, { getAppName } from "../../cui/commonComponents/AppNameToggle";
import { isEmpty, isNull } from "lodash";

type DatagridRefType = DataGrid;

const {
  UPDATE_FILES_METADATA,
  UPDATE_FILES_LOCATION,
  DOWNLOAD_FILES,
  UPLOAD_FILES,
  UPDATE_IR_METADATA,
  SEND_FILES_TO_DUO,
  UPDATE_AND_MOVE_FILES,
} = SelectName;
const dropDownOptions = [
  UPDATE_FILES_METADATA,
  UPDATE_FILES_LOCATION,
  DOWNLOAD_FILES,
  UPLOAD_FILES,
  UPDATE_IR_METADATA,
  SEND_FILES_TO_DUO,
  UPDATE_AND_MOVE_FILES,
];
const initialStateData = {
  pending: false,
  fulfilled: false,
  sites: [],
  totalCount: 0,
};

export const UpdateMetadataLocationFiles = () => {
  const { user } = useContext(OidcIdentityContext);
  const [data, setData] = useState<updateSites>(initialStateData);
  const [selectedRows, setSelectedRows] = useState<GetAllSitesDTO[]>([]);
  const [dropDown, setDropDown] = useState<string>(UPDATE_FILES_METADATA);
  const refreshFlag = useRecoilValue(refreshFlagAtom);
  const { showSnackbar } = useSnackbar();
  const dataGridRef = useRef<DatagridRefType>(null);
  const [pageNumber, setpageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>(null)
  const appName = useRecoilValue(getAppName);

  const fetchSites = async () => {
    dataGridRef.current!.instance.clearSelection();
    setData({ pending: true, fulfilled: true, sites: [], totalCount:0 });
    try {
      const syncType = (() => {
        if (dropDown === UPDATE_FILES_METADATA) return SyncTypeEnum.UPDATE_FILES_METADATA;
        if (dropDown === UPDATE_FILES_LOCATION) return SyncTypeEnum.UPDATE_FILES_LOCATION;
        if (dropDown === DOWNLOAD_FILES) return SyncTypeEnum.DOWNLOAD_FILES;
        if (dropDown === UPLOAD_FILES) return SyncTypeEnum.UPLOAD_FILES;
        if (dropDown === UPDATE_IR_METADATA) return SyncTypeEnum.UPDATE_IR_METADATA;
        if (dropDown === SEND_FILES_TO_DUO) return SyncTypeEnum.SEND_FILES_TO_DUO;
        if (dropDown === UPDATE_AND_MOVE_FILES) return SyncTypeEnum.UPDATE_AND_MOVE_FILES;
        return null;
      })();

      if (syncType) {
        const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
        const newData = await taxPortalClientApi.taxPortal_GetAllSites(
          syncType,
          null,
          appName !==null ? appName:"PERF",
          startIndex,
          defaultPageSize
        );
        setData({
          ...initialStateData,
          fulfilled: true,
          sites: newData.data,
          totalCount: newData.totalCount,
        });
      }
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
      setData(initialStateData);
    }
  };

  const onSelectionchanged = (e: any) => {
    setSelectedRows(e.selectedRowsData);
  };
  const cellRenderForDate = (e: string) => {
    return <div>{convertUTCTimeToLocal(e)}</div>;
  };
  const cellRenderForMessage = (e: any) => {
    if (e.data.message === null && e.data.status === null) {
      return null;
    }
    return <div title={e.data.message}>{e.data.status}</div>;
  };
  const handleDropDownChange = (e: SelectChangeEvent) => {
    setDropDown(e.target.value);
    dataGridRef.current!.instance.clearSelection();
  };
  const sendRequest = async (eventType: ServiceBusEnum) => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    const data = selectedData.map(
      (e: GetAllSitesDTO): ServiceMessage => ({
        siteId: e.hqSiteId,
        userName: user?.profile.preferred_username,
        eventType,
        mdmClientId: e.mdmClientId,
        appName:appName
      })
    );
    try {
      await taxPortalClientApi.taxPortal_InsertTopic(data);
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    } finally {
      dataGridRef.current!.instance.clearSelection();
    }
  };
  const handleRunClick = () => {
    showSnackbar({ message: SnackbarMessage.UPDATE_FILE });
    if (dropDown === UPDATE_FILES_METADATA) sendRequest(ServiceBusEnum.UPDATE_FILES_METADATA);
    if (dropDown === UPDATE_FILES_LOCATION) sendRequest(ServiceBusEnum.UPDATE_FILES_LOCATION);
    if (dropDown === DOWNLOAD_FILES) sendRequest(ServiceBusEnum.DOWNLOAD_FILES);
    if (dropDown === UPLOAD_FILES) sendRequest(ServiceBusEnum.UPLOAD_FILES);
    if (dropDown === UPDATE_IR_METADATA) sendRequest(ServiceBusEnum.UPDATE_IR_METADATA);
    if (dropDown === SEND_FILES_TO_DUO) sendRequest(ServiceBusEnum.SEND_FILES_TO_DUO);
    if (dropDown === UPDATE_AND_MOVE_FILES) sendRequest(ServiceBusEnum.UPDATE_AND_MOVE_FILES);
  };
  const refreshButtonOptions = {
    icon: "refresh",
    onClick: fetchSites,
  };
  const handleSyncClick = async () => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    const data = selectedData.map((e: GetAllSitesDTO): ServiceMessage => {
      return {
        siteId: e.hqSiteId,
        userName: user?.profile.preferred_username,
        eventType: ServiceBusEnum.SYNC_CEM,
        mdmClientId: e.mdmClientId,
      };
    });
    try {
      await taxPortalClientApi.taxPortal_InsertTopic(data);
      showSnackbar({ message: SnackbarMessage.SYNC_CEM });
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };

  useEffect(() => {
    if(searchTerm === null || searchTerm?.length  === 0) {
      fetchSites();
    }
  }, [dropDown, refreshFlag, pageNumber, appName, searchTerm]);

  const fetchSitesBySearchTerms = async () => {
    console.log("enter");
    setData({ pending: true, fulfilled: true, sites: [], totalCount:0 });
    try {
      const syncType = (() => {
        if (dropDown === UPDATE_FILES_METADATA) return SyncTypeEnum.UPDATE_FILES_METADATA;
        if (dropDown === UPDATE_FILES_LOCATION) return SyncTypeEnum.UPDATE_FILES_LOCATION;
        if (dropDown === DOWNLOAD_FILES) return SyncTypeEnum.DOWNLOAD_FILES;
        if (dropDown === UPLOAD_FILES) return SyncTypeEnum.UPLOAD_FILES;
        if (dropDown === UPDATE_IR_METADATA) return SyncTypeEnum.UPDATE_IR_METADATA;
        if (dropDown === SEND_FILES_TO_DUO) return SyncTypeEnum.SEND_FILES_TO_DUO;
        if (dropDown === UPDATE_AND_MOVE_FILES) return SyncTypeEnum.UPDATE_AND_MOVE_FILES;
        return null;
      })();

      if (syncType) {
        const res = await taxPortalClientApi.taxPortal_GetAllSiteSearch(syncType, appName !==null ? appName:"PERF", searchTerm);
        if(res != null){

          setData({ pending: false, fulfilled: true, sites: res.data, totalCount: res.totalCount });
        }
        else{
          setData({ pending: false, fulfilled: true, sites: [], totalCount: 0 });

        }


      }
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
      setData(initialStateData);
    }
  };

  useEffect(() => {
    if(!isNull(searchTerm) && !isEmpty(searchTerm)){
      fetchSitesBySearchTerms();
    }
  }, [dropDown, refreshFlag, appName, searchTerm]);

  return (
    <div style={{ marginLeft: "20px", marginRight: "20px" }}>
      <div style={{ marginLeft: "3px", marginTop: "16px" }}>
        <div style={{
          display: "flex",
          margin: "10px 0 10px 0",
        }}>
        <h2>Execute Files Location/Metadata Updates</h2>
        <AppNameToggle />
      </div>
      </div>
      <DataGrid
        height={calculateGridHeight(270)}
        onSelectionChanged={onSelectionchanged}
        {...dataGridConfig}
        dataSource={data.sites}
        noDataText={data.pending ? "Loading..." : "No data"}
        ref={dataGridRef}
      >
        <Selection mode="multiple" selectAllMode={"allPages"} showCheckBoxesMode={"onClick"} />
        <Scrolling mode="standard" />
        <LoadPanel enabled={false} />
        <SearchPanel placeholder={"Global Search..."} onTextChange={(e)=>{
          setSearchTerm(e);
        }} visible />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        {/* <Paging enabled={true} defaultPageSize={20} /> */}
        <Scrolling columnRenderingMode="virtual" />
        <Toolbar>
          <Item location="after">
            <Button onClick={handleSyncClick} disabled={!selectedRows.length} variant="outlined">
              Sync CEM
            </Button>
          </Item>
          <Item location="after">
            <FormControl size="medium" variant="outlined" sx={{ width: 300 }}>
              <Select
              value={dropDown}
                style={{ height: 36, fontSize: "14px" }}
                defaultValue={UPDATE_FILES_METADATA}
                onChange={handleDropDownChange}
              >
                {dropDownOptions.map((option) => (
                  <MenuItem key={option} value={option} style={{ fontSize: "14px" }}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
          <Item location="after">
            <Button
              onClick={handleRunClick}
              disabled={!selectedRows.length}
              variant="outlined"
            >
              RUN
            </Button>
          </Item>
          <Item name="searchPanel" location="after" />
          <Item widget="dxButton" options={refreshButtonOptions} />
        </Toolbar>
        <Column width="70px" alignment="left" dataField="hqSiteId" caption={"SiteID"} />
        <Column width="70px" alignment="left" dataField="mdmClientId" caption={"MasterID"} />
        <Column width="70px" alignment="left" dataField="mdmClientId" caption={"ClientID"} />
        <Column dataField="hqClientName" caption={"SiteName"} />
        <Column width="170px" dataField="siteCreatedBy" caption={"Site Created by"} />
        <Column
          width="190px"
          cellRender={(e) => cellRenderForDate(e.data.siteCreated)}
          dataField="Site Created Date / Time"
          caption={"Site Created Date / Time"}
        />
        <Column cellRender={cellRenderForMessage} width="70px" dataField="status" caption={"Status"} />
        <Column width="170px" dataField="lastModifiedBy" caption={"Last Updated by"} />
        <Column
          width="190px"
          cellRender={(e) => cellRenderForDate(e.data.lastModified)}
          dataField="Last Update Date/Time"
          caption={"Last Update Date/Time"}
        />
      </DataGrid>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "5px 0 0 0",
        }}
      >
        <Pagination
          onChange={(e: any, pageNumber: any) => {
            setpageNumber(pageNumber);
          }}
          count={Math.ceil(data.totalCount / defaultPageSize)}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
};
