import { useContext, useEffect, useState } from "react";
import { models, Report, Embed } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import {
  EmbeddedReportDTO,
  ExternalUserSite,
  PowerBIReport
} from "../../../api-client-nswag/taxportal-client";
import "../PowerBIReport.scss";
import { OidcIdentityContext } from "@rsmus/react-auth/dist/OidcIdentity";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { snackbarState } from "../../../cui/commonComponents/CustomSnackbar";
import UnAuthPowerBi from "../../../cui/commonComponents/UnAuthPowerBi";
import SiteNameDropdown from "./SiteNameDropdown";
import { getNewAccessToken, timeOutForRefreshToken } from "../../../util/Utils";
import { isNull } from "lodash";
import SyncIcon from "@mui/icons-material/Sync";
import { userPermissions } from "../../../App";

interface selectedOptionType {
  value: number;
  label: string | undefined;
}

interface siteNameType {
  pending: boolean;
  data: ExternalUserSite[];
}

export const CS_GlobalDirect = () => {
  const [report, setReport] = useState<Report | null>(null);
  const [filters, setFilters] = useState<any[]>([]);
  const { user } = useContext(OidcIdentityContext);
  const setSnackbar = useSetRecoilState(snackbarState);
  const [isDisabled, setIsDisabled] = useState(false);
  const userPermission = useRecoilValue(userPermissions)
  var siteId = parseInt(sessionStorage.getItem("siteId")!);
  const [siteID, setSiteID] = useState<number>();
  const [flag, setFlag] = useState<boolean | null>(null);
  const [selectedOption, setSelectedOption] = useState<selectedOptionType | null>(null);
  const [siteName, setSiteName] = useState<siteNameType>({
    pending: true,
    data: [],
  });
  const options = siteName?.data?.map((name) => ({
    value: name.id,
    label: name.sitename
  }));
  const [tokenTimeout, setTokenTimeout] = useState<any>(null);
  const [tokenExpiration, setTokenExpiration] = useState<Date | null>(null);

  const handleOnChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    if (report) {
      setTimeout(async () => {
        await report.refresh();
      }, 2000);
    }
  };

  const [reportConfigDTO, setReportConfig] = useState<models.IReportEmbedConfiguration>({
    type: "report",
    embedUrl: "",
    tokenType: models.TokenType.Embed,
    accessToken: "",
    settings: undefined,
  });

  useEffect(() => {
    const fetchData = async () => {
      setSiteName({ pending: true, data: [] });
      const data = await taxPortalClientApi.highQ_GetUserSites(user?.profile.preferred_username);
      setSiteName({ pending: false, data: data });
    };
    fetchData();
  }, [user]);

  useEffect(() => {
    if (!isNull(siteId) && !isNaN(siteId)) {
      setIsDisabled(true)
      setSiteID(siteId)
    } else if (selectedOption) {
      setSiteID(selectedOption.value);
      setIsDisabled(false)
    }
  }, [selectedOption, siteID]);

  useEffect(() => {
    if (siteID !== null) {
      loadReportConfig();
    }
  }, [siteID]);

  useEffect(() => {
    if (siteID !== null) {
      const fil = basicfilterSchema(
        "vw_GlobalDirect_Insight_TaxSummary",
        "Siteid",                               
        "In",
        siteID
      );
      setFilters([fil]);
    }
  }, [siteID]);

  const loadReportConfig = async () => {
    if (siteID !== null) {
      await taxPortalClientApi
        .reports_GetEmbedReport(PowerBIReport.CS_GLOBAL_DIRECT, userPermission)
        .then((res) => {
          const settings = {
            type: "report",
            embedUrl: res?.embedUrl,
            accessToken: res?.token,
            settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: false,
            },
          };

          setReportConfig(settings);
          setFlag(false);
          const expiresIn = new Date(res.expiration);
        expiresIn.setMinutes(expiresIn.getMinutes() - 2); 
        setTokenExpiration(expiresIn);
        })
        .catch((err) => {
          if (err.status === 401) {
            setFlag(true);
          }
        });
    }
  };

  const basicfilterSchema = (
    tableName: string,
    columnName: string,
    operator: string,
    field: unknown | null
  ) => {
    return {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: tableName,
        column: columnName,
      },
      filterType: models.FilterType.Basic,
      operator: operator,
      values: [field],
      requireSingleSelection: true,
    };
  };

  useEffect(() => {
    if (flag !== null && flag === false) {
      if (report && filters.length > 0) {
        const onLoadedHandler = () => {
          report
            .setFilters(filters)
            .then(() => console.log("Filters applied successfully."))
            .catch(() => console.log("Failed to apply filters."));
        };
        report.off("loaded", onLoadedHandler);
        report.on("loaded", onLoadedHandler);
      }
    }
  }, [report, filters, flag]);

  const syncIconClick = async () => {
    try {
      if (siteID !== null) {
      setSnackbar({
        open: true,
        message: "Global Direct data synchronization in progress",
        severity: "info",
      });
      var refreshUrl = await taxPortalClientApi.reports_GetCSPowerBIRefreshURL(PowerBIReport.CS_GLOBAL_DIRECT) + siteID;
      if (refreshUrl) {
        window.open(refreshUrl, '_blank', 'noopener,noreferrer');
    } else {
        console.error('URL is null');
    }
    }
    } catch (error) {
      console.log(error);
    }
    if (report) {
      setTimeout(async () => {
        await report.refresh();
      }, 2000);
    }
  };

  const refreshAccessToken = async () => {
    if (report) {
      const res = await getNewAccessToken(PowerBIReport.CS_GLOBAL_DIRECT);
      if (res && res?.token !== null) {
        const newToken = res.token;
        report.setAccessToken(newToken);

        const expiresIn = new Date();
        expiresIn.setHours(expiresIn.getHours() + 1);
        setTokenExpiration(expiresIn);

        if (tokenTimeout) {
          clearTimeout(tokenTimeout);
        }

        const currentTime = new Date();
        const refreshTime =
          expiresIn.getTime() - currentTime.getTime() - 2 * 60 * 1000;
        if (refreshTime > 0) {
          setTokenTimeout(setTimeout(refreshAccessToken, refreshTime));
        }
      }
    } else {
      console.error("Failed to refresh token.");
    }
  };
  
  useEffect(() => {
    timeOutForRefreshToken(tokenExpiration, setTokenTimeout, refreshAccessToken)
    return () => {
      if (tokenTimeout) {
        clearTimeout(tokenTimeout);
      }
    };
  }, [report, tokenExpiration]);

  return (
    <>
    { isDisabled === true ? null :
      <div
        style={{
          display: "flex",
          margin: "16px 0 16px 16px",
          justifyContent: "space-between",
        }}
      >
        <SiteNameDropdown
          options={options}
          handleOnChange={handleOnChange}
          siteName={siteName}
          selectedOption={selectedOption}
        />
      </div>
    }
      <div className="powerBi-container">
        <div className="powerBi-container">
          {flag === true ? (
            <UnAuthPowerBi name="CS Global Direct" />
          ) : (
            <div style={{ position: "relative", width: "95%" }}>
              <PowerBIEmbed
                embedConfig={reportConfigDTO}
                cssClassName="report-style-class"
                getEmbeddedComponent={(embedObject: Embed) => {
                  setReport(embedObject as Report);
                }}
              />
              <div
              style={{
                position: "absolute",
                top: 10,
                right: 40,
                cursor: "pointer",
              }}
            >
              {reportConfigDTO.settings !== undefined && (
                <SyncIcon
                  onClick={syncIconClick}
                  style={{ color: "#009cde", fontSize: "35px" }}
                />
              )}
            </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
