import Moment from "moment";
import { FormatDate } from "../api-client-nswag/taxportal-client";
import { taxPortalClientApi } from "../api-client-nswag/taxportal-client-runtime";

export const convertUTCTimeToLocal = (e: string, format: string = FormatDate.AC) => {
  if (e === null) {
    return "";
  }
  return Moment.utc(e).local().format(format);
};

export const convertFormatDate = (e: string, format: string = FormatDate.HQ) => {
  if (e === null) {
    return "";
  }
  return Moment(e).format(format);
};

export function calculateGridHeight(height: number) {
  const windowHeight = window.innerHeight ?? 600;
  return windowHeight - height;
}

export function calculateGridWidth(width: number) {
  const windowWidth = window.innerWidth;
  return windowWidth - width;
}

export const selectValues = (arr: string[]) =>
  arr.map((value) => ({
    ID: value.toLowerCase(),
    Name: value !== "NULL" ? value : "--",
    disabled: value === "NULL",
  }));

export const becomeUpperCase = (str: string) => str.toUpperCase();

export const toCapitalCase = (str: string) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export const getExtension = (file: string) => file!.split(".").pop()!;

export const getNewAccessToken = async (type: number) => {
  try {
    const res = await taxPortalClientApi.reports_GetPowerBIToken(type);
    console.log(typeof res, "res");
    return res;
  } catch (error) {
    console.error("Error getting new access token:", error);
    window.location.href = `${window.location.origin}/signout`; 
    return null;
  }
};
export const timeOutForRefreshToken = (tokenExpiration: Date | null, setTokenTimeout: any, refreshAccessToken: any) => {
  if (tokenExpiration) {
    const currentTime = new Date();
    const refreshTime = tokenExpiration.getTime() - currentTime.getTime() - 5 * 60 * 1000;
    if (refreshTime > 0) {
      setTokenTimeout(setTimeout(refreshAccessToken, refreshTime));
    }
  }
};

export const getCurrentUTCTimestamp = () => {
  return Moment.utc().format();
};

export const executeDownload = (
  base64String: string | ArrayBuffer,
  identifier: string,
  fieName: string,
  fileExtension: string
) => {
  const blobArray = typeof base64String === "string" ? defineBlob(base64String) : base64String;

  const type =
    fileExtension === "xlsx" ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "application/zip";

  const blob = new Blob([blobArray], { type });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  const currentTime = convertUTCTimeToLocal(getCurrentUTCTimestamp(), FormatDate.NAME);
  link.setAttribute("download", `${identifier}${fieName}${currentTime}.${fileExtension}`);
  document.body.appendChild(link);
  link.click();
  if (link.parentNode) link.parentNode.removeChild(link);
};

const defineBlob = (base64String: string) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
  return new Uint8Array(byteNumbers);
};
