import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { useContext, useEffect } from "react";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { refreshFlagAtom, upgradeSitesResponseAtom } from "../../constants";
import { AlertColor, snackbarState } from "../../cui/commonComponents/CustomSnackbar";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import { CEMUserClients, CEMUserDetails, CEMUserRecord } from "../../api-client-nswag/taxportal-client";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import "./_home.scss";


function Home(props:any) {
  const setSnackbar = useSetRecoilState(snackbarState);

  
  const { user, logout } = useContext(OidcIdentityContext);
  const setRefreshFlag = useSetRecoilState(refreshFlagAtom);
  const setUpgradeSitesResponse = useSetRecoilState(upgradeSitesResponseAtom);

  


  const handleSnackbar = (message: string, type: AlertColor) => {
    isJsonString(message) ? setUpgradeSitesResponse(message) : showSnackbar(message, type);
  };

  const isJsonString = (message: string) => {
    try {
      JSON.parse(message);
      return true;
    } catch (e) {
      return false;
    }
  };

  const showSnackbar = (message: string, type: AlertColor) => {
    setSnackbar({
      open: true,
      message: message,
      severity: type,
    });
    setRefreshFlag((prevState) => !prevState);
  };


  return (
    <>
      
    </>
  );
}

export default Home;
